require("../utils/throttle");

googletag.cmd.push(function () {
  googletag.pubads().addEventListener("slotRenderEnded", function (event) {
    const slotId = event.slot.getSlotElementId();

    // If the slot is leaderboard:
    if (slotId.includes("leaderboard")) {
      const leaderboard = $(".ad-position.leaderboard");

      // Stick Leadboard ads for 2 seconds after being loaded.
      $(window).on(
        "scroll",
        essThrottle(function () {
          if (!$(window).scrollTop()) {
            return; // return if page is not scrolled
          }
          setTimeout(function () {
            leaderboard.addClass("fade");
            setTimeout(() => {
              leaderboard.removeClass("sticky fade");
            }, 500);
          }, 2000);
        })
      );
    }
  });
});
